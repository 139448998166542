<template>
    <div class="footer-group">
        <div class="footer-main">
            <ul class="foot-nav">
                <li>关于我们</li>
                <li>商务合作</li>
                <li>法律声明</li>
                <li>隐私策略</li>
                <li>联系我们</li>
                <li>服务条款</li>
            </ul>
            <p class="copyright-text">Copyright © 2020 云搬砖 All Rights Reserved 蜀ICP备20010740号-1</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "footerPage",
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/base";
    .footer-group{
        min-width: 1200px;
        height: 192px;
        background-color: #1b1e2e;
        padding-top: 63px;
        box-sizing: border-box;
        .footer-main{
            width: 1200px;
            margin: 0 auto;
            .foot-nav{
                @include flex-center;
                li{
                    font-size: 14px;
                    color: #fff;
                    padding: 0 20px;
                    cursor: pointer;
                    position: relative;
                    line-height: 20px;
                    z-index: 1;
                    &::before{
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1px;
                        height: 14px;
                        background-color: #fff;
                    }
                    &:first-child::before{
                        width: 0;
                    }
                    &:hover{
                        opacity: .85;
                    }
                }
            }
            .copyright-text{
                font-size: 14px;
                color: #fff;
                margin-top: 9px;
                text-align: center;
            }
        }
    }
</style>
