export const menuConfig = [
    {
        pageNum: "0",
        haveChild: false,
        title: '首页',
        titleEn: 'home',
        router: 'home',
    },
    {
        pageNum: "1",
        haveChild: false,
        title: '产品中心',
        titleEn: 'Product Center',
        router: 'product',
    },
    {
        pageNum: "2",
        haveChild: false,
        title: '功能特色',
        titleEn: 'Functional features',
        router: 'function',
    },
    {
        pageNum: "3",
        haveChild: false,
        title: '入驻商家',
        titleEn: 'Settled businesses',
        router: 'client',
    },
    {
        pageNum: "4",
        haveChild: false,
        title: '商务合作',
        titleEn: 'Business cooperation',
        router: 'cooperation',
    }
]
