<template>
    <div :class="['header-group', {'white-back': currentIndexNum !== '0' || !atTop}]">
        <div class="header-main">
            <div class="logo">
                <img v-if="currentIndexNum !== '0' || !atTop" src="../../assets/image/home/logo_blue.png" alt="">
                <img v-else src="../../assets/image/home/logo_white.png" alt="">
            </div>
            <div class="navigation-group">
                <el-menu :default-active="currentIndexNum" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item
                        :index="item.pageNum"
                        v-for="item in items"
                        :key="item.pageNum"
                        @click="toggleChangeRouter(item)"
                    >
                        <p class="tit">{{item.title}}</p>
                        <p class="tit-en">{{item.titleEn}}</p>
                    </el-menu-item>
                </el-menu>
                <div class="to-store-page" @click="toStoreIndex">
                    <p class="tit">商家入驻</p>
                    <p class="tit-en">Merchants settled</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {menuConfig} from "./menuConfig";
    export default {
        name: "navigation",
        data(){
            return {
                items: menuConfig,
                atTop: true,//页面是否在顶部
            }
        },
        computed: {
            currentIndexNum () {
                return this.$store.state.pageIndex
            }
        },
        mounted() {
            //判断当前页面
            this.windowListener();
            //添加滚动事件
            window.addEventListener("scroll",this.handleScroll);
        },
        destroyed() {
            document.removeEventListener('scroll', this.handleScroll)
        },
        methods: {
            handleSelect() {
                // console.log(key, keyPath);
            },
            //跳转至商家中心
            toStoreIndex(){
                window.open('http://www.chinachem.cn/#/login', '_blank');
            },
            //切换导航栏的页面
            toggleChangeRouter(single) {
                if(!single.haveChild && single.pageNum !== this.currentIndexNum){
                    this.$store.commit("updatePage", single.pageNum);
                    this.$router.push(single.router);
                }
            },
            //获取页面滚动时
            handleScroll() {
                //获取滚动时的高度
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop > 0) {
                    //滚动大于0的时候要做的操作
                    this.atTop = false;
                }else{
                    this.atTop = true;
                }
            },
            //页面刷新是判断当前页面
            windowListener() {
                let path = this.$route.path;
                let menuLists = menuConfig;
                let currentPageNum;
                for(let i = menuLists.length; i--; ){
                    let currentParent = menuLists[i];
                    if(currentParent.haveChild){
                        for(let j = currentParent.children.length; j--; ){
                            let currentChild = currentParent.children[j];
                            if('/index/' + currentChild.router === path){
                                currentPageNum = currentChild.pageNum;
                                break;
                            }
                        }
                    }else{
                        if('/index/' + currentParent.router === path){
                            currentPageNum = currentParent.pageNum;
                            break;
                        }
                    }
                }
                this.$store.commit("updatePage", currentPageNum);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/css/base";
    .header-group{
        position: fixed;
        z-index: 9;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        .header-main{
            width: 1200px;
            margin: 0 auto;
            @include flex-between;
            .logo{
                width: 102px;
                height: 24px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .navigation-group{
                @include flex-left;
                .to-store-page{
                    text-align: center;
                    padding: 17px 20px;
                    cursor: pointer;
                    .tit{
                        font-size: 18px;
                        color: #fff;
                        line-height: 24px;
                    }
                    .tit-en{
                        font-size: 12px;
                        color: #fff;
                        line-height: 16px;
                        margin-top: 6px;
                        text-transform: uppercase;
                    }
                    &:hover{
                        background-color: #fff;
                        .tit,
                        .tit-en{
                            color: $redColor;
                        }
                    }
                }
                .el-menu{
                    background-color: transparent;
                    &.el-menu--horizontal{
                        border: none;
                        .el-menu-item{
                            height: 80px;
                            line-height: initial;
                            text-align: center;
                            padding: 17px 20px 0;
                            box-sizing: border-box;
                            min-width: 100px;
                            .tit{
                                font-size: 18px;
                                color: #fff;
                                line-height: 24px;
                            }
                            .tit-en{
                                font-size: 12px;
                                color: #fff;
                                line-height: 16px;
                                margin-top: 6px;
                                text-transform: uppercase;
                            }
                            &:hover{
                                background-color: #fff;
                                .tit,
                                .tit-en{
                                    color: $mainColor;
                                }
                            }
                            &.is-active{
                                background-color: $mainColor;
                                border: none;
                                .tit,
                                .tit-en{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.white-back{
            background-color: #fff;
            .header-main{
                .navigation-group{
                    .el-menu{
                        &.el-menu--horizontal{
                            .el-menu-item{
                                .tit{
                                    color: #333;
                                }
                                .tit-en{
                                    color: #333;
                                }
                                &:hover{
                                    background-color: #f7f7f7;
                                    .tit,
                                    .tit-en{
                                        color: $mainColor;
                                    }
                                }
                                &.is-active{
                                    background-color: $mainColor;
                                    border: none;
                                    .tit,
                                    .tit-en{
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .to-store-page{
                        .tit,
                        .tit-en{
                            color: $redColor;
                        }
                        &:hover{
                            background-color: #f7f7f7;
                        }
                    }
                }
            }
        }
    }
</style>
