<template>
  <div>
    <navigation></navigation>
    <router-view></router-view>
    <footer-page></footer-page>
  </div>
</template>

<script>
  import Navigation from '../components/navigation/navigation'
  import FooterPage from '../components/footer-page'
    export default {
        name: "index",
      components: {
        Navigation,
        FooterPage
      },
      data(){
        return {

        }
      },
      methods: {

      }
    }
</script>

<style scoped lang="scss">

</style>
